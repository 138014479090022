var $ = require('jquery');
window.jQuery = window.$ = $;
var slick = require('slick-carousel');

import './less/main.less';

function changeHeaderOnScroll() {
    var initialHeaderHeight = $('.header').outerHeight();
    if ($(window).scrollTop() > initialHeaderHeight) {
        $('.header').addClass('over');
    } else {
        $('.header').removeClass('over');
    }
};

function changeMobileHeaderOnScroll() {
    var initialMobileHeaderHeight = $('.header-mobile').outerHeight();
    if ($(window).scrollTop() > initialMobileHeaderHeight) {
        $('.header-mobile').addClass('over');
    } else {
        $('.header-mobile').removeClass('over');
    }
};

// Side menu change a.active on page scroll
function activeSecionInView() {

    var scrollDistance = $(window).scrollTop();
    $('.page-section').each(function(i) {
        if ($(this).position().top <= scrollDistance + 100) {
            $('.side-menu a.active').removeClass('active');
            $('.side-menu a').eq(i).addClass('active');
        }
    });
};

// Scrollto animation
$(document).ready(function() {
    $('a.scrollto').on('click', function(e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body')
            .stop()
            .animate(
                {
                    scrollTop: $target.offset().top,
                },
                500,
                'swing',
                function() {
                    window.location.hash = target;
                }
            );
    });

    $(window).scroll(function() {
        changeHeaderOnScroll();
        changeMobileHeaderOnScroll();
        if($('.page-section').length > 0) {
            activeSecionInView();
        };
    });

    // Toggle menu
    $('.header-burger').on('click', function() {
        $('.header').addClass('show');
        $('body').addClass('noscroll');
    });

    $('.header-close').on('click', function() {
        $('.header').removeClass('show');
        $('body').removeClass('noscroll');
    });

    // Share links
    $('.share-link').on('click', function(e) {
        e.preventDefault();
        var link = $(this).attr('href');
        console.log(link);
        var windowPopup = window.open(link, 'titolo','scrollbars=no,resizable=yes,width=600,height=600,status=no,location=no,toolbar=no');
    });

    // Slick Carousel
    $('.slider').slick({
        slidesToScroll: 1,
        slidesToShow: 3,
        variableWidth: true,
        speed: 300,
        infinite: true,
        nextArrow: '.next',
        prevArrow: '.prev',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  variableWidth: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                }
            },
        ]
    });
});